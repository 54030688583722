import React from "react";
import { graphql } from "gatsby";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { GatsbyImage } from "gatsby-plugin-image";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { navigate } from "@reach/router";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const MediaNews = ({ data, location }) => {
  const intl = useIntl();

  const post = data.contentfulMediaRoomNews;

  // 公開設定がfalse / nullの場合はリダイレクト
  if (!post.title) {
    navigate("/news/");
    return null;
  }

  const Bold = ({ children }) => <p className="bold">{children}</p>;
  const Text = ({ children }) => <p className="align-center">{children}</p>;
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
    renderText: (text) => text.replace("!", "?"),
  };

  return (
    <Layout location={location} mode="beige">
      <Seo title="" />
      <div className="post mediaroom_post bg-bge">
        <div className="container container-document">
          <h1>{post.title}</h1>

          <div className="main flex-row">
            <div className="postContent flex-column-7">
              <p className="post__meta">
                <p className="h2 txt txt-rot">{post.category}</p>
                <span className="post__date">
                  {post.updatedAt ? post.updatedAt : post.createdAt}
                </span>
              </p>

              {post.thumbnail && ( //もしサムネイル画像をもっていれば
                <GatsbyImage
                  image={post.thumbnail && post.thumbnail.gatsbyImageData}
                  className="mediaroom_thumbnail mb-6"
                />
              )}
              <p className="mb-8">
                {documentToReactComponents(
                  JSON.parse(post.content.raw),
                  options
                )}
              </p>
              <button onClick={() => navigate(-1)} className="link-simple-black button-init">
                {intl.locale === "en"
                  ? `go back`
                  : `Zurück`}
              </button>
            </div>
          </div>
        </div>
      </div>
      <section className="sect sect-qa bg-rot">
        <div className="container">
          <p className="h2 txt mb-5">
            {intl.locale === "en" ? `Media Room` : `Multimedia`}
          </p>
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Clinical Publications`
                    : `Klinische Studien`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  We envision a future where humans and technology are
                  seamlessly interfaced, feeding off of each other.
                </p>
                <Link to="/research/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en" ? `Recruiting Inc.` : `Karriere`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  We envision a future where humans and technology are
                  seamlessly interfaced, feeding off of each other.
                </p>
                <Link to="/recruit/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </section>
    </Layout>
  );
};
export default MediaNews;
export const pageQuery = graphql`
  query ($slug: String, $language: String) {
    contentfulMediaRoomNews(
      slug: { eq: $slug }
      node_locale: { eq: $language }
    ) {
      id
      title
      content {
        raw
      }
      thumbnail {
        gatsbyImageData
        file {
          url
        }
      }
      slug
      category
      updatedAt(formatString: "YYYY/MM/DD")
      createdAt(formatString: "YYYY/MM/DD")
    }
  }
`;
